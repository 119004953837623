<template>
  <div :class="`card card-product ${additionalItemClasses}`">
    <router-link :to="to">
      <div v-if="imageUrl" class="card-img-top p-2 mt-2">
        <vue-clazy-load v-if="imageUrl" :src="imageUrl">
          <!-- The image slot renders after the image loads. -->
          <transition name="fade">
            <img
              :alt="item.Description"
              :src="`${imageUrl}`"
              crossorigin="anonymous"
              class="rounded"
            />
          </transition>
          <!-- The placeholder slot displays while the image is loading. -->
          <transition slot="placeholder" name="fade">
            <!-- You can put any component you want in here. -->
            <img
              v-if="imageUrl"
              alt="Placeholder"
              crossorigin="anonymous"
              class="rounded"
              src="/img/placeholder.jpg"
            />
          </transition>
        </vue-clazy-load>

        <img v-else class="d-block mx-auto" src="/img/itemFallback.png" />
      </div>
    </router-link>
    <div
      v-if="item.Type === 'Composition' && !imageUrl"
      class="card-img-top p-2 mt-2"
    >
      <ImageGrid :images="compostionImages" />
    </div>
    <div class="card-body text-left d-flex flex-column">
      <a v-if="item.Brand" class="product-brand" href="#">{{ item.Brand }}</a>
      <h3 class="product-title font-size-sm">
        <router-link :to="this.routePath">
          <strong class="text-primary"></strong>
          {{ item.Description }}
        </router-link>
      </h3>
      <div class="content">
        <item-description :description="item.MediumDescription" :to="to" />
      </div>

      <div class="mt-auto">
        <div class="product-price">
          <p v-if="price">
            <strong class>{{ price }} {{ $t('item.euroRentalPrice') }}</strong>
          </p>
        </div>

        <item-button-add-to-cart
          v-if="availableInWarehouse && addToCartButton"
          :icon="true"
          :item="item"
          class="ml-3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageGrid from '@/components/items/ImageGrid'
import ItemButtonAddToCart from './ItemButtonAddToCart'
import ItemDescription from './ItemDescription'
// @ is an alias to /src
import { addToCart, getCart } from '@/services/CartService'
import { VueClazyLoad } from 'vue-clazy-load'

export default {
  name: 'Item',
  components: {
    ImageGrid,
    ItemDescription,
    VueClazyLoad,
    'item-button-add-to-cart': ItemButtonAddToCart
  },
  props: {
    addToCartButton: {
      required: false,
      type: Boolean,
      default: function() {
        return false
      }
    },
    item: {
      type: Object,
      default: function() {
        return {}
      }
    },
    suggestedItem: {
      type: Boolean,
      required: false,
      default: function() {
        return false
      }
    }
  },
  data: function() {
    return {
      addToCartLoading: false
    }
  },
  computed: {
    cartCount: function() {
      return this.$store.state.cartCount
    },
    itemPrefixUrlPath: function() {
      if (this.$route.params.categoryID) return this.$route.path
      if (this.$route.path.includes('zoeken'))
        return `/${this.$i18n.locale}/shop`
      return `/${this.$i18n.locale}/shop/${process.env.VUE_APP_DEFAULT_CATEGORY_ID}`
    },

    user: function() {
      return this.$store.getters.user
    },
    availableInWarehouse: function() {
      if (this.$store.state.activeWarehouse === '') return true
      if (this.item.WarehouseIDs.includes(this.$store.state.activeWarehouse))
        return true
      return false
    },
    additionalItemClasses: function() {
      if (!this.availableInWarehouse) {
        return 'unavailable'
      } else {
        return ''
      }
    },
    imageUrl: function() {
      if (this.item.Images?.length) {
        return `${process.env.VUE_APP_URL}${this.item.Images[0]}`
      } else {
        return null
      }
    },
    compostionImages: function() {
      if (this.item.Type === 'Composition') {
        let images = this.item.Items.map(
          item => process.env.VUE_APP_URL + item.DefaultImage.ImageUrl
        )
        return images.slice(0, 4)
      }
      return null
    },
    routePath: function() {
      if (this.suggestedItem)
        return `/${this.$i18n.locale}/shop/artikel/${this.item.ID}`
      return `${this.itemPrefixUrlPath}/artikel/${this.item.ID}`
    },
    to: function() {
      return { path: this.routePath, params: { lang: this.$i18n.locale } }
    },
    price: function() {
      if (!this.item.ItemPeriods) return null
      if (this.item.ItemPeriods[1]) {
        return this.item.ItemPeriods[1].PriceValue.toFixed(2)
          .toString()
          .replace('.', ',')
      }
      if (this.item.ItemPeriods[0]) {
        return this.item.ItemPeriods[0].PriceValue.toFixed(2)
          .toString()
          .replace('.', ',')
      }
      return null
    }
  },
  methods: {
    randomNumber: function() {
      return Math.floor(Math.random() * (11 - 99 + 1) + 99)
    },
    navigateToItem: function() {
      window.location = this.routePath
    },
    addToCart: async function() {
      this.addToCartLoading = true
      let itemID = null
      let compositionID = null

      if (this.item.Type == 'Composition') {
        compositionID = this.item.ID
      } else {
        itemID = this.item.ID
      }
      const result = await addToCart({
        itemID,
        compositionID,
        amount: 1
      })

      if (result != undefined) {
        this.$toasted.show(
          `${this.$t('product')} ${this.itemData.Description} ${this.$t(
            'added-to-your-cart'
          )}`
        )
      }
      await getCart()

      this.addToCartLoading = false
    }
  }
}
</script>
<style scoped lang="scss">
.unavailable {
  filter: grayscale(100%) opacity(50%);
}
img {
  cursor: pointer;
}
.card {
  background-color: rgba(0, 0, 0, 0);
  img {
    max-width: 100%;
  }
  br {
    display: none;
  }
  height: 100% !important;
  .buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.card:after {
  margin-bottom: 10px;
}
.card-product {
  border: none;
}
.product-price {
  margin-top: -20px;
}
.content {
  min-height: 105px;
}

.text-accent {
  color: #4e54c8 !important;
}

.product-title {
  min-height: 50px;
}

h3 {
  font-size: 1.3rem !important;
  a {
    color: #373f50 !important;
  }
  strong {
    color: #373f50 !important;
  }
}
.product-brand {
  transition: color 0.25s ease-in-out;
  color: #7d879c;
  text-decoration: none !important;
  font-size: 0.75rem !important;
}

.card-img-top {
  min-height: 172px;
}
.content {
  margin-bottom: 45px;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  transition: opacity 3s ease-in-out;
}
.fade-in {
  opacity: 1;
}
</style>
