<template>
  <span>
    <b-button
      v-if="!icon"
      @click="addToCart()"
      variant="secondary"
      class="mb-2 mr-2"
      >{{ $t('item.addToCart') }}
      <font-awesome-icon
        v-if="loading"
        :icon="['fad', 'spinner']"
        class="fa-spin"
        far
    /></b-button>

    <b-button
      v-if="icon"
      @click="addToCart()"
      variant="secondary"
      class="mb-2 mr-2"
    >
      <font-awesome-icon v-if="!loading" fas icon="shopping-cart"/>
      <font-awesome-icon
        v-if="loading"
        :icon="['fad', 'spinner']"
        class="fa-spin"
        far
    /></b-button>
  </span>
</template>

<script>
import { getCart, addToCart } from '@/services/CartService'

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      loading: false
    }
  },
  computed: {
    dateTimeBusinessStart: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    dateTimeBusinessEnd: function() {
      return this.$store.state.DateTimeBusinessEnd
    }
  },
  methods: {
    addToCart: async function() {
      let compositionID = null
      let itemID = this.item.ID

      this.loading = true

      if (this.item.Type === 'Composition') {
        compositionID = itemID
        itemID = null
      }

      const result = await addToCart({
        itemID,
        compositionID,
        dateTimeBusinessStart: this.dateTimeBusinessStart,
        dateTimeBusinessEnd: this.dateTimeBusinessEnd
      })

      if (result.status === 204 || result.status === 400) {
        this.notify({
          message: this.$t('item.itemNotAvailableForLocationOrDate')
        })
        this.loading = false
        return
      }

      this.notify({
        message: this.$t('message.itemAddedToCart', {
          itemDescription: this.itemDescription
        })
      })

      await getCart()

      this.loading = false
    },
    notify({ message }) {
      this.$toasted.show(message)
    }
  }
}
</script>

<style></style>
